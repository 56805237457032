import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import FaqSchema from '../components/Shared/Schema/Faq'
import Header from '../components/TestosteroneComparisonPage/Header'
import Products from '../components/TestosteroneComparisonPage/Products'
import Filter from '../components/TestosteroneComparisonPage/Filter'
import BuildingBlocks from '../components/TestosteroneComparisonPage/BuildingBlocks'
import Ingredients from '../components/TestosteroneComparisonPage/Ingredients'
import Explanation from '../components/TestosteroneComparisonPage/Explanation'
import Customers from '../components/TestosteroneComparisonPage/Customers'
import Expanding from '../components/TestosteroneComparisonPage/Expanding'
import Protocols from '../components/TestosteroneComparisonPage/Protocols'
import Faq from '../components/TestosteroneComparisonPage/Faq'
import FoodAndDrugAdministration from '../components/TestosteroneComparisonPage/FoodAndDrugAdministration'
import References from '../components/TestosteroneComparisonPage/References'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Filter custom={data.custom} products={data.products} />,
      priority: data.products?.priority || 99,
    },
    {
      render: <Products custom={data.custom} products={data.products} />,
      priority: data.products?.priority || 99,
    },
    {
      render: <BuildingBlocks buildingBlocks={data.buildingBlocks} />,
      priority: data.buildingBlocks?.priority || 99,
    },
    {
      render: <Ingredients ingredients={data.ingredients} />,
      priority: data.ingredients?.priority || 99,
    },
    {
      render: <Explanation explanation={data.explanation} />,
      priority: data.explanation?.priority || 99,
    },
    {
      render: <Customers customers={data.customers} />,
      priority: data.customers?.priority || 99,
    },
    {
      render: <Expanding expanding={data.expanding} />,
      priority: data.expanding?.priority || 99,
    },
    {
      render: <Protocols protocols={data.protocols} />,
      priority: data.protocols?.priority || 99,
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    },
    {
      render: <FoodAndDrugAdministration foodAndDrugAdministration={data.foodAndDrugAdministration} />,
      priority: data.foodAndDrugAdministration?.priority || 99,
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99,
    },
  ].sort((a, b) => (a.priority >= b.priority ? 1 : -1))

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      {!data.custom?.filterVersion && <Header header={data.header} />}
      {components.map(component => component.render)}
    </Layout>
  )
}
