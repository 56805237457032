import React from 'react'
import { sanityImageUrl, urlWithSearchParamsHandler, prepareParagraph } from '../../../utils/format'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ custom, products }) => {
  if (!products || products.isHide || custom?.filterVersion) return null

  return (
    <div className={styles.products}>
      <div className={styles.wrap}>
        <div className={styles.desktop}>
          <div className={styles.items}>
            {products.items &&
              products.items.map(product => (
                <div className={styles.item} key={product._key}>
                  <p className={styles.top}>{product.top}</p>
                  <Link to={product.rating.url} className={styles.imageBox}>
                    <img src={sanityImageUrl(product.image)} className={styles.image} alt={product.image?.caption} />
                    {product.isPrescription && <p className={styles.tag}>Prescription</p>}
                  </Link>
                  {product.rating?.url && (
                    <Link className={styles.rating} to={product.rating.url}>
                      {product.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(product.rating.starsImage)} alt={product.rating?.text}/>}
                      {product.rating.text && <p>{product.rating.text}</p>}
                    </Link>
                  )}
                  <p className={styles.name}>{product.title}</p>
                </div>
              ))}
          </div>

          <div className={styles.items}>
            {products.items &&
              products.items.map(product => (
                <div className={styles.item} key={product._key}>
                  <div className={styles.included}>
                    {product.included &&
                      product.included.map((item, index) => (
                        <p className={styles.includedItem} key={index}>
                          {item}
                        </p>
                      ))}
                  </div>
                </div>
              ))}
          </div>

          <div className={styles.items}>
            {products.items &&
              products.items.map(product => (
                <div className={styles.item} key={product._key}>
                  <p className={styles.description}>{product.Description}</p>
                </div>
              ))}
          </div>

          <div className={styles.items}>
            {products.items &&
              products.items.map(product => (
                <div className={styles.item} key={product._key}>
                  <div className={cx(styles.values, {
                    [styles.valuesOne]: !product.totalTestosteroneText || !product.freeTestosteroneText
                  })}>
                    {product.totalTestosteroneText && (
                      <div className={styles.total}>
                        <p className={styles.valuesTitle}>{product.totalTestosteroneText}</p>
                        <p className={styles.valuesResult}>
                          {product.totalArrow && product.totalArrow === 'up' && <span className={styles.arrowUp} />}
                          {product.totalArrow && product.totalArrow === 'down' && <span className={styles.arrowDown} />}
                          {product.totalTestosteroneValue}
                        </p>
                      </div>
                    )}
                    {product.freeTestosteroneText && (
                      <div className={styles.free}>
                        <p className={styles.valuesTitle}>{product.freeTestosteroneText}</p>
                        <p className={styles.valuesResult}>
                          {product.freeArrow && product.freeArrow === 'up' && <span className={styles.arrowUp} />}
                          {product.freeArrow && product.freeArrow === 'down' && <span className={styles.arrowDown} />}
                          {product.freeTestosteroneValue}
                        </p>
                      </div>
                    )}
                  </div>
                  <p className={styles.price} dangerouslySetInnerHTML={{ __html: product.price }} />
                  <div className={styles.buttons}>
                    <a
                      href={product.getStartedButtonUrl}
                      onClick={urlWithSearchParamsHandler}
                      className={styles.buttonsStarted}
                    >
                      {product.getStartedButtonText}
                    </a>
                    <Link className={styles.buttonsMore} to={product.learnMoreButtonUrl}>
                      {product.learnMoreButtonText}
                    </Link>
                  </div>
                  <div className={styles.benefits}>
                    {product.benefits &&
                      product.benefits.map((benefit, index) => (
                        <p className={styles.benefitsItem} key={index}>
                          {prepareParagraph(benefit)}
                        </p>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className={styles.mobile}>
          <div className={styles.items}>
            {products.items &&
              products.items.map(product => (
                <div className={styles.item} key={product._key}>
                  <p className={styles.top}>{product.top}</p>
                  <Link to={product.rating.url} className={styles.imageBox}>
                    <img src={sanityImageUrl(product.image)} className={styles.image} alt={product.image?.caption} />
                    {product.isPrescription && <p className={styles.tag}>Prescription</p>}
                  </Link>
                  {product.rating?.url && (
                    <Link className={styles.rating} to={product.rating.url}>
                      {product.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(product.rating.starsImage)} alt={product.rating?.text}/>}
                      {product.rating.text && <p>{product.rating.text}</p>}
                    </Link>
                  )}                 
                  <p className={styles.name}>{product.title}</p>
                  <div className={styles.included}>
                    {product.included &&
                      product.included.map((item, index) => (
                        <p className={styles.includedItem} key={index}>
                          {item}
                        </p>
                      ))}
                  </div>
                  <p className={styles.description}>{product.Description}</p>
                  <div className={cx(styles.values, {
                    [styles.valuesOne]: !product.totalTestosteroneText || !product.freeTestosteroneText
                  })}>
                    {product.totalTestosteroneText && (
                      <div className={styles.total}>
                        <p className={styles.valuesTitle}>{product.totalTestosteroneText}</p>
                        <p className={styles.valuesResult}>
                          {product.totalArrow && product.totalArrow === 'up' && <span className={styles.arrowUp} />}
                          {product.totalArrow && product.totalArrow === 'down' && <span className={styles.arrowDown} />}
                          {product.totalTestosteroneValue}
                        </p>
                      </div>
                    )}
                    {product.freeTestosteroneText && (
                      <div className={styles.free}>
                        <p className={styles.valuesTitle}>{product.freeTestosteroneText}</p>
                        <p className={styles.valuesResult}>
                          {product.freeArrow && product.freeArrow === 'up' && <span className={styles.arrowUp} />}
                          {product.freeArrow && product.freeArrow === 'down' && <span className={styles.arrowDown} />}
                          {product.freeTestosteroneValue}
                        </p>
                      </div>
                    )}
                  </div>
                  <p className={styles.price} dangerouslySetInnerHTML={{ __html: product.price }} />
                  <div className={styles.buttons}>
                    <a
                      href={product.getStartedButtonUrl}
                      onClick={urlWithSearchParamsHandler}
                      className={styles.buttonsStarted}
                    >
                      {product.getStartedButtonText}
                    </a>
                    <Link className={styles.buttonsMore} to={product.learnMoreButtonUrl}>
                      {product.learnMoreButtonText}
                    </Link>
                  </div>
                  <div className={styles.benefits}>
                    {product.benefits &&
                      product.benefits.map((benefit, index) => (
                        <p className={styles.benefitsItem} key={index}>
                          {prepareParagraph(benefit)}
                        </p>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
